import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import './Translation/i18n';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';

const children = (
	<AuthContextProvider>
		<ThemeContextProvider>
			<Router>
				<App />
			</Router>
		</ThemeContextProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');

createRoot(container as Element).render(children);

reportWebVitals();
