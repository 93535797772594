import React from 'react';
import ReactLoading from 'react-loading';

const Loader = () => (
  <div className="loader">
    <ReactLoading color="#ffffff" type="spinningBubbles" height={'10%'} width={'10%'} className='margin-auto' />
  </div>
);

export default Loader;
