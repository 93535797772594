import React, {SVGProps} from 'react';

const SvgSettingsVoice = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M12 11c.56 0 .99-.44.99-1L13 4c0-.55-.45-1-1-1s-1 .45-1 1v6c0 .55.45 1 1 1z'
				opacity={0.3}
			/>
			<path d='M7 22h2v2H7zm5-9c1.66 0 2.99-1.34 2.99-3L15 4c0-1.66-1.34-3-3-3S9 2.34 9 4v6c0 1.66 1.34 3 3 3zm-1-9c0-.55.45-1 1-1s1 .45 1 1v6c0 .56-.44 1-1 1-.55 0-1-.45-1-1V4zm0 18h2v2h-2zm4 0h2v2h-2zm4-12h-1.7c0 3-2.54 5.1-5.3 5.1S6.7 13 6.7 10H5c0 3.41 2.72 6.23 6 6.72V20h2v-3.28c3.28-.49 6-3.31 6-6.72z' />
		</svg>
	);
};

export default SvgSettingsVoice;
