export default function useIsMobile(setIsMobile: Function): () => void {
    function handleResize() {
        setIsMobile(window.innerWidth < 768);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
}
