import React, {SVGProps} from 'react';

const SvgSignalWifiStatusbarConnectedNoInternet2 = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M17 11.21V8h5.92C19.97 5.51 16.16 4 12 4 7.31 4 3.07 5.9 0 8.98l4.23 4.24A10.96 10.96 0 0117 11.21z'
				fillOpacity={0.3}
			/>
			<path d='M4.23 13.22L12 21l5-5.01v-4.78a10.96 10.96 0 00-12.77 2.01zM19 18h2v2h-2zM19 10h2v6h-2z' />
		</svg>
	);
};

export default SvgSignalWifiStatusbarConnectedNoInternet2;
