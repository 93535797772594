import React, {SVGProps} from 'react';

const SvgDirectionsBoat = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M6.49 15.68L8 13.96l1.51 1.72c.34.4 1.28 1.32 2.49 1.32 1.21 0 2.15-.92 2.49-1.32L16 13.96l1.51 1.72c.2.23.6.64 1.14.94l1.12-3.97-2.39-.78L12 10.11l-5.38 1.77-2.4.79 1.13 3.96c.55-.31.94-.72 1.14-.95zM11 3h2v1h-2z'
				opacity={0.3}
			/>
			<path d='M3.95 19H4c1.6 0 3.02-.88 4-2 .98 1.12 2.4 2 4 2s3.02-.88 4-2c.98 1.12 2.4 2 4 2h.05l1.89-6.68c.08-.26.06-.54-.06-.78s-.34-.42-.6-.5L20 10.62V6c0-1.1-.9-2-2-2h-3V1H9v3H6c-1.1 0-2 .9-2 2v4.62l-1.29.42a1.007 1.007 0 00-.66 1.28L3.95 19zM11 3h2v1h-2V3zM6 6h12v3.97L12 8 6 9.97V6zm.62 5.87L12 10.11l5.38 1.77 2.39.78-1.12 3.97c-.54-.3-.94-.71-1.14-.94L16 13.96l-1.51 1.72c-.34.4-1.28 1.32-2.49 1.32-1.21 0-2.15-.92-2.49-1.32L8 13.96l-1.51 1.72c-.2.23-.6.63-1.14.93l-1.13-3.96 2.4-.78zM8 22.01c1.26.64 2.63.97 4 .97s2.74-.32 4-.97c1.26.65 2.62.99 4 .99h2v-2h-2c-1.39 0-2.78-.47-4-1.32-1.22.85-2.61 1.28-4 1.28s-2.78-.43-4-1.28C6.78 20.53 5.39 21 4 21H2v2h2c1.38 0 2.74-.35 4-.99z' />
		</svg>
	);
};

export default SvgDirectionsBoat;
