import React, {SVGProps} from 'react';

const SvgSettingsBrightness = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M3 19.01h18V4.99H3v14.02zm5-8.51V8h2.5L12 6.5 13.5 8H16v2.5l1.5 1.5-1.5 1.5V16h-2.5L12 17.5 10.5 16H8v-2.5L6.5 12 8 10.5z'
				opacity={0.3}
			/>
			<path d='M8 16h2.5l1.5 1.5 1.5-1.5H16v-2.5l1.5-1.5-1.5-1.5V8h-2.5L12 6.5 10.5 8H8v2.5L6.5 12 8 13.5V16zm4-7c1.66 0 3 1.34 3 3s-1.34 3-3 3V9zm9-6H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16.01H3V4.99h18v14.02z' />
		</svg>
	);
};

export default SvgSettingsBrightness;
