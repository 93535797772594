import React, { Fragment, Suspense, useEffect } from 'react';
import ContentRoutes from './ContentRoutes';
import { useLocation } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';

const LOADING = (
	<Fragment>
	</Fragment>
);

const Content = () => {
	const [isMobile, setIsMobile] = React.useState(false);
	const { pathname } = useLocation();

	useEffect(() => useIsMobile(setIsMobile), []);

	return (
		<main className={`${pathname === '/' ? 'landing-no-margin ' : 'all-pages-mobile-margin '}content`}>
			<Suspense fallback={LOADING}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
