import { AUTH_ID, AUTH_ROLE, AUTH_USERNAME, COMPANY_ID, COMPANY_UUID } from '../../constants/auth';
import { AVATAR, LANGUAGE, LANGUAGE_ID } from '../../constants/local_storage';
import { ROLE } from '../../constants/constants';

export function getLoggedCompanyId(): number {
	return +localStorage.getItem(COMPANY_ID)!;
}

export function getLoggedCompanyUuid(): string {
	return localStorage.getItem(COMPANY_UUID)!;
}

export function getLoggedUserId(): number {
	return +localStorage.getItem(AUTH_ID)!;
}

export function getLoggedUsername(): string {
	return localStorage.getItem(AUTH_USERNAME) ? localStorage.getItem(AUTH_USERNAME)! : '';
}

export function getLoggedUserRole(): string {
	return localStorage.getItem(AUTH_ROLE) ?? '';
}

export function getLoggedAvatar(): string {
	return localStorage.getItem(AVATAR) ?? '';
}

export function getLanguage(): string {
	return localStorage.getItem(LANGUAGE) ?? 'it';
}

export function getLanguageId(): number {
	return localStorage.getItem(LANGUAGE_ID) ? +localStorage.getItem(LANGUAGE_ID)! : 1;
}

export function isRole(input: string): boolean {
	return input === getLoggedUserRole();
}

export function changeRole(setCurrentRole: Function): void {
	if (getLoggedUserRole() === ROLE.USER) {
		localStorage.setItem(AUTH_ROLE, ROLE.IDEATOR);
	} else {
		localStorage.setItem(AUTH_ROLE, ROLE.USER);
	}
	setCurrentRole(localStorage.getItem(AUTH_ROLE) ?? ROLE.USER);
}
