import React from 'react';
import { Route, RouteProps, Routes } from 'react-router-dom';
import footers from '../../routes/footerRoutes';

const FooterRoutes = () => {
	return <Routes>
		{footers.map((page: RouteProps) => {
			return <Route key={page.path} {...page} />;
		})}
	</Routes>;
};

export default FooterRoutes;
