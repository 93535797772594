import React from 'react';
import styled from '@emotion/styled';

/* const MenuButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border: none;
	background-color: transparent;
	cursor: pointer;
	border-radius: 3rem;

	&:hover {
		background-color: #f2f2f255;
	}
`;
 */
interface IconProps {
	size: string;
	color?: string;
}

export const Icon = styled.img<IconProps>`
	width: ${(props) => props.size};
	height: ${(props) => props.size};
	margin-right: 1rem;
	margin-left: 1rem;
	color: ${(props) => props.color || 'white'};
`;
interface MenuButtonProps {
	onClick: () => void;
	iconPosition?: 'left' | 'right';
	iconSrc?: string;
	iconSize?: string;
	color?: string;
	circular?: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({
	iconSize,
	iconSrc,
	onClick,
	iconPosition = 'left',
	color,
    circular
}) => {
	return (
		<div className='menuButton-dropdown' onClick={onClick}>
			<Icon
				src={iconSrc}
				alt='icon'
				color='black'
				style={{ scale: '1', borderRadius: circular ? '50%' : undefined }}
				size={iconSize || '1.5rem'}
			/>
		</div>
	);
};

export default MenuButton;
