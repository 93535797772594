import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { consoleError } from '../log';
import showNotification from '../components/extras/showNotification';
import React from 'react';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { CLIENT_ID } from '../constants/local_storage';
import { getLoggedUserId } from '../common/function/utilities';
import { jwtDecode } from 'jwt-decode';

const isTokenExpired = (token: string): boolean => {
    if (!token) return true;
    try {
        const decodedToken: any = jwtDecode(token);
        const currentTime: number = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};

const handleError = (setLoading: Function, error: any): void => {
    setLoading(false);

    if (error === 'TOKEN_EXPIRED') {
        showNotification(<></>, <h5>'Token expired'</h5>, 'danger'); // TODO
    } else if (error.response?.status === 401) {
        showNotification(<></>, <h5>{t('error.unauthorized')}</h5>, 'danger');
    } else if (error.response?.status === 404) {
        showNotification(<></>, <h5>{t('error.not-found')}</h5>, 'danger');
    } else {
        showNotification(<></>, <h5>{t('error.general')}</h5>, 'danger');
    }

    consoleError('Error ' + error.response.status + ' ' + error.response.statusText + ' =============> ', error.response);
};

export function requestInterceptor(setLoading: Function): void {
    axios.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
            setLoading(true);

            const accessToken: string | null = localStorage.getItem('accessToken');
            if (accessToken) {
                if (isTokenExpired(accessToken)) {
                    handleError(setLoading, 'TOKEN_EXPIRED');
                    return Promise.reject();
                }
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            if (config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            } else {
                config.headers["Content-Type"] = 'application/json';
            }

            config.headers['Cache-Control'] = 'no-cache';
            config.headers['Pragma'] = 'no-cache';
            config.headers['Expires'] = '0';

            let clientId: string | null = sessionStorage.getItem(CLIENT_ID);
            if (!clientId) {
                clientId = (getLoggedUserId() ? getLoggedUserId() + "_" : "") + uuidv4();
                sessionStorage.setItem(CLIENT_ID, clientId);
            }
            config.headers[CLIENT_ID] = clientId;
            config.headers['X-Request-ID'] = uuidv4();

            return config;
        },
        (err) => {
            handleError(setLoading, err);
            return Promise.reject(err);
        }
    );
}

export function responseInterceptor(setLoading: Function): void {
    axios.interceptors.response.use(
        (response: AxiosResponse<any, any>) => {
            if (response.status === 201) {
                showNotification(<></>, <h5>{t('success.create')}</h5>, 'success');
            } else if (response.status === 202) {
                showNotification(<></>, <h5>{t('success.edit')}</h5>, 'success');
            } else if (response.status === 203) {
                showNotification(<></>, <h5>{t('success.delete')}</h5>, 'success');
            }

            setLoading(false);
            return response;
        },
        (err): null => {
            handleError(setLoading, err);
            return null;
        },
    );
}
