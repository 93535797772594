import React, {SVGProps} from 'react';

const SvgPhoneMissed = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M18.6 17.22c.66.37 1.28.79 1.88 1.27l1.07-1.07c-.91-.75-1.9-1.39-2.95-1.9v1.7zM3.53 18.5c.58-.47 1.21-.89 1.87-1.27v-1.71a15.13 15.13 0 00-2.95 1.9l1.08 1.08z'
				opacity={0.3}
			/>
			<path d='M23.71 16.67C20.66 13.78 16.54 12 12 12S3.34 13.78.29 16.67c-.18.18-.29.43-.29.71 0 .28.11.53.29.71l2.48 2.48c.18.18.43.29.71.29.27 0 .52-.11.7-.28.79-.74 1.69-1.36 2.66-1.85.33-.16.56-.5.56-.9v-3.1c1.45-.48 3-.73 4.6-.73s3.15.25 4.6.72v3.1c0 .39.23.74.56.9.98.49 1.87 1.12 2.67 1.85.18.18.43.28.7.28.28 0 .53-.11.71-.29l2.48-2.48c.18-.18.29-.43.29-.71 0-.28-.12-.52-.3-.7zm-18.31.56c-.66.37-1.29.8-1.87 1.27l-1.07-1.07c.91-.75 1.9-1.39 2.95-1.9v1.7zm15.08 1.26c-.6-.48-1.22-.9-1.88-1.27v-1.7c1.05.51 2.03 1.15 2.95 1.9l-1.07 1.07zM7 6.43l4.94 4.94 7.07-7.07-1.41-1.42-5.66 5.66L8.4 5H11V3H5v6h2z' />
		</svg>
	);
};

export default SvgPhoneMissed;
