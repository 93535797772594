import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { DEFAULT_LANGUAGE } from "../constants/constants";
import translationItalian from "./Italian/translation.json";
import translationEnglish from "./English/translation.json";

const resources = {
  "it-It": {
    translation: translationItalian
  },
  it: {
    translation: translationItalian
  },
  en: {
    translation: translationEnglish
  }
};

i18next
  .use(LanguageDetector) 
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE

  });

export default i18next;
