import LogoImage from '../assets/logo.png';
import Logo512Image from '../assets/logo512.png';
import LogoExtendedImage from '../assets/logo-extended.png';
import PlusSelectedImage from '../assets/img/plus-white.svg';
import MinusSelectedImage from '../assets/img/minus-white.svg';
import PlusNotSelectedImage from '../assets/img/plus-blu.svg';
import MinusNotSelectedImage from '../assets/img/minus-blu.svg';

import DeleteImage from '../assets/img/delete.svg';
import DownloadImage from '../assets/img/download.svg';
import UploadImage from '../assets/img/upload.svg';

export const LOGO_IMAGE = LogoImage;
export const LOGO_EXTENDED_IMAGE = LogoExtendedImage;
export const LOGO_512_IMAGE = Logo512Image;
export const PLUS_SELECTED_IMAGE = PlusSelectedImage;
export const MINUS_SELECTED_IMAGE = MinusSelectedImage;
export const PLUS_NOT_SELECTED_IMAGE = PlusNotSelectedImage;
export const MINUS_NOT_SELECTED_IMAGE = MinusNotSelectedImage;
export const DELETE_IMAGE = DeleteImage;
export const DOWNLOAD_IMAGE = DownloadImage;
export const UPLOAD_IMAGE = UploadImage;
