import React, {SVGProps} from 'react';

const SvgFace = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path
				d='M17.5 8c.46 0 .91-.05 1.34-.12C17.44 5.56 14.9 4 12 4c-.46 0-.91.05-1.34.12C12.06 6.44 14.6 8 17.5 8zM8.08 5.03a8.046 8.046 0 00-3.66 4.44 8.046 8.046 0 003.66-4.44z'
				opacity={0.3}
			/>
			<path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 2c2.9 0 5.44 1.56 6.84 3.88-.43.07-.88.12-1.34.12-2.9 0-5.44-1.56-6.84-3.88.43-.07.88-.12 1.34-.12zM8.08 5.03a8.046 8.046 0 01-3.66 4.44 8.046 8.046 0 013.66-4.44zM12 20c-4.41 0-8-3.59-8-8 0-.05.01-.1.01-.15 2.6-.98 4.68-2.99 5.74-5.55a9.942 9.942 0 009.92 3.46c.21.71.33 1.46.33 2.24 0 4.41-3.59 8-8 8z' />
			<circle cx={9} cy={13} r={1.25} />
			<circle cx={15} cy={13} r={1.25} />
		</svg>
	);
};

export default SvgFace;
