import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EvoHuntMobileHeader from '../../../components/evohunt/common/ui/EvoHuntMobileHeader';
import EvoHuntDesktopHeader from '../../../components/evohunt/common/ui/header/EvoHuntDesktopHeader';
import useIsMobile from '../../../hooks/useIsMobile';

const DashboardHeader = () => {
	const [isMobile, setIsMobile] = React.useState(false);

	useEffect(() => useIsMobile(setIsMobile), []);

	const navigate = useNavigate();
	const handleBack = (): void => {
		navigate(-1);
	};

	return (
		<>
			{isMobile ? (
				<EvoHuntMobileHeader handleMenuOpen={(): void => {}} handleBack={handleBack} />
			) : (
				<EvoHuntDesktopHeader handleBack={handleBack} />
			)}
		</>
	);
};

export default DashboardHeader;
